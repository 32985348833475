// define a mixin object
import NumberOnly from "@/util/NumberOnly";

export default {
  methods: {
    isNumber: function(evt) {
      NumberOnly(evt);
    }
  }
};
