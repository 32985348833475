<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-12 w-full py-3 sm:py-6">
    <div id="payment"></div>
    <div class="card_container">
      <form
        id="card-form"
        action=""
        class="grid grid-cols-8 gap-4 px-3 md:px-0 mt-8 mb-6 sm:mb-0 w-full"
      >
        <div class="col-span-8 sm:col-span-8">
          <label
            class="text-xs font-semibold text-prime-gray"
            for="card-holder-name"
            >Card Holder Name</label
          >

          <div
            class="card_field block w-full h-10 mt-1 pt-2 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray text-prime-gray"
          >
            <input
              type="text"
              id="card-holder-name"
              disabled="disabled"
              readonly="readonly"
            />
          </div>
        </div>
        <div class="col-span-8 sm:col-span-8">
          <label class="text-xs font-semibold text-prime-gray" for="card-number"
            >Card Number</label
          >

          <div
            id="card-number"
            class="card_field block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
          ></div>
        </div>
        <div class="col-span-8 sm:col-span-4">
          <div>
            <label
              class="text-xs font-semibold text-prime-gray"
              for="expiration-date"
              >Expiration Date</label
            >

            <div
              id="expiration-date"
              class="card_field block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
            ></div>
          </div>
        </div>
        <div class="col-span-8 sm:col-span-4">
          <div>
            <label class="text-xs font-semibold text-prime-gray" for="cvv"
              >CVV</label
            >
            <div
              id="cvv"
              class="card_field block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
            ></div>
          </div>
        </div>
        <div
          v-for="(error,index) in errors"
          :key="index"
          id="errormsg"
          class="col-span-8 bg-rose-600 rounded text-white p-2"
        >
          {{ typeof error == 'string' ? error : error.html }}
        </div>
        <div class="col-span-8 sm:col-span-8 mt-3">
          <button
            value="submit"
            id="submit"
            class="text-white font-medium btn mx-auto block hover:bg-accent rounded-sm w-50 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
          >
            <template v-if="loadingPay">
              <b-spinner small class="mr-2"></b-spinner>
              <span class="text-small">{{ loadingText }}</span>
            </template>
            <span v-else> Pay </span>
          </button>
        </div>
        <div class="col-span-8 sm:col-span-8 mx-auto">
          <!-- PayPal Logo -->
          <table border="0" cellpadding="3" cellspacing="0" align="center">
            <tr>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="center">
                <a
                  href="https://www.paypal.com/webapps/mpp/paypal-popup"
                  title="How PayPal Works"
                  onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=960, height=600'); return false;"
                >
                  <img
                    src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg"
                    border="0"
                    alt="PayPal Acceptance Mark"
                    style="width: 260px"
                  />
                </a>
              </td>
            </tr>
          </table>
          <!-- PayPal Logo -->
        </div>
        <div class="col-span-8 sm:col-span-8">
          <button
            @click="goBack"
            id="cancel"
            class="text-white font-medium btn mx-auto block hover:bg-accent rounded-sm w-50 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { OrderCompleted } from "@/services/user.service";

export default {
  name: "PayPalCard",
  components: {},
  data() {
    return {
      paypal: undefined,
      loadingPay: false,
      loadingCancel: false,
      errors: [],
    };
  },
  props: {
    gateway: {
      type: Object,
      default: null,
    },
    buttonColor: {
      type: String,
      default: "#00aff2",
    },
    user: {
      type: Object,
      default: null,
    },
  },
  async mounted() {
    document.getElementById("submit").style.backgroundColor = this.buttonColor;
    document.getElementById("cancel").style.backgroundColor = this.buttonColor;
    await this.initializePayPal(
      this.gateway.clientId,
      this.gateway.clientToken,
      this.gateway.orderId
    );
    document.getElementById("card-holder-name").value = this.user.cardName;
  },
  computed: {},
  methods: {
    goBack() {
      console.log("CANCEL PA APA ")
      this.$emit("cancel");
    },
    async initializePayPal(clientId, clientToken, orderId) {
      try {
        this.paypal = await loadScript({
          "client-id": clientId,
          "data-client-token": clientToken,
          components: "hosted-fields",
        });
        if (this.paypal.HostedFields.isEligible()) {
          const cardFields = await this.paypal.HostedFields.render({
            createOrder: function (/*data, actions*/) {
              return orderId;
            },
            styles: {
              ".valid": {
                color: "green",
              },
              ".invalid": {
                color: "red",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          });

          // then(function (cardFields) {
          document
            .querySelector("#card-form")
            .addEventListener("submit", (event) => {
              event.preventDefault();
              this.errors = [];
              this.loadingPay = true;
              cardFields
                .submit({
                  cardholderName:
                    document.getElementById("card-holder-name").value,
                  // Billing Address
                  // billingAddress: {
                  //   countryCodeAlpha2: document.getElementById(
                  //     "card-billing-address-country"
                  //   ).value,
                  // },
                })
                .then(async (/* data,  actions*/) => {
                  const capture = await OrderCompleted(orderId);
                  // const orderData = capture.data.content.capture;
                  const user = capture.data.content.user;

                  // Three cases to handle:
                  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //   (2) Other non-recoverable errors -> Show a failure message
                  //   (3) Successful transaction -> Show confirmation or thank you

                  // This example reads a v2/checkout/orders capture response, propagated from the server
                  // You could use a different API or structure for your 'orderData'
                  // var errorDetail =
                  //   Array.isArray(orderData.details) && orderData.details[0];

                  // if (
                  //   errorDetail &&
                  //   errorDetail.issue === "INSTRUMENT_DECLINED"
                  // ) {
                  //   return actions.restart(); // Recoverable state, per:
                  //   // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                  // }

                  // if (errorDetail) {
                  //   var msg =
                  //     "Sorry, your transaction could not be processed.";
                  //   if (errorDetail.description)
                  //     msg += "\n\n" + errorDetail.description;
                  //   if (orderData.debug_id)
                  //     msg += " (" + orderData.debug_id + ")";
                  //   return alert(msg); // Show a failure message (try to avoid alerts in production environments)
                  // }

                  // // Successful capture! For demo purposes:
                  // console.log(
                  //   "Capture result",
                  //   orderData,
                  //   JSON.stringify(orderData, null, 2)
                  // );

                  // var transaction =
                  //   orderData.purchase_units[0].payments.captures[0];
                  // alert(
                  //   "Transaction " +
                  //     transaction.status +
                  //     ": " +
                  //     transaction.id +
                  //     "\n\nSee console for all available details"
                  // );

                  // this.$route.replace(
                  //   `/generate/${user.campaignName}/${user.uid}`
                  // );
                  window.location.href = `/generate/${user.campaignName}/${user.uid}`;

                  // Replace the above to show a success message within this page, e.g.
                  // const element = document.getElementById('paypal-button-container');
                  // element.innerHTML = '';
                  // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                  // Or go to another URL:  actions.redirect('thank_you.html');
                })
                .catch((error) => {
                  this.loadingPay = false;
                  console.log("ERRROROROR ",error)
                  if (error.response.data && error.response.data.details) {
                    this.errors = error.response.data.details
                  }else{
                    this.errors.push({ type: 'danger', html: "Something went wrong. Please try again." })
                  }
                  console.log("Submit failed ", error);
                });
            });
          // });
        } else {
          this.errors.push({ type: 'danger', html: "Something went wrong. Please try again." })
          console.log("NO HOSTED FIELDS");
        }
      } catch (error) {
        this.errors.push({ type: 'danger', html: "Something went wrong. Please try again." })
        console.log("PayPal SDK Failed ", error);
      }
    },
  },
};
</script>

<style scoped></style>
