<template>
  <div class="align-items-start">
    <!-- <div class="text-3xl font-bold text-start mb-10">Share Your Dedication</div> -->
    <div class="mb-4">
      <VideoCard
        key="preview-card"
        :heightPreview="true"
        :item="selectedVideo"
        :index="99"
        :fnPreview="fnPreview"
        :dedicationText="dedicationText"
        :firstName="firstName"
        :lastName="lastName"
        :city="city"
        :state="state"
        :overlayText="true"
        :textColor="textColor"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VideoCard from "@/components/VideoCard.vue";

export default {
  name: "DedicationInfo",
  components: {
    VideoCard,
  },
  props: {
    firstName: null,
    lastName: null,
    city: null,
    state: null,
    dedicationText: {
      type: String,
    },
    slideImage:{
      type: String
    },
    textColor:{
      type: String
    },
    font:{
      type: String
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    fnPreview: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectedVideo: null
    };
  },
  created(){
    this.selectedVideo = Object.assign({}, this.selectedItem)
    if(this.slideImage){
      this.selectedVideo.previewImage = this.slideImage;
    }
  },
};
</script>

<style scoped></style>
