<template>
  <div class="mt-16">
    <div id="payment"></div>
    <div class="col-span-8 rounded p-2" id="msg">
      {{ msg }}
    </div>
    <div class="col-span-8 sm:col-span-8">
      <button
        @click="goBack"
        id="cancel"
        class="
          text-white
          font-medium
          btn
          block
          hover:bg-accent
          rounded-sm
          w-100
          py-2
          transition-all
          duration-200
          shadow-hard
          hover:shadow-2xl
        "
      >
        Go Back
      </button>
    </div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { OrderCaptured } from "@/services/user.service";

export default {
  name: "PayPalCard",
  components: {},
  data() {
    return {
      paypal: undefined,
      showMsg: false,
      msg: "",
    };
  },
  props: {
    gateway: {
      type: Object,
      default: null,
    },
    buttonColor: {
      type: String,
      default: "#b22234",
    },
    user: {
      type: Object,
      default: null,
    },
  },
  async mounted() {
    document.getElementById("cancel").style.backgroundColor = this.buttonColor;
    await this.initializePayPal(
      this.gateway.clientId,
      this.gateway.clientToken,
      this.gateway.orderId
    );
  },
  computed: {},
  methods: {
    goBack() {
      this.$emit("cancel");
    },
    async initializePayPal(clientId, clientToken, orderId) {
      try {
        this.paypal = await loadScript({
          "client-id": clientId,
          "data-client-token": clientToken,
          components: "buttons",
        });

        this.paypal
          .Buttons({
            fundingSource: this.paypal.FUNDING.CARD,
            createOrder: function (/*data, actions*/) {
              return orderId;
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then(async (orderData) => {
                const element = document.getElementById("msg");
                element.innerHTML = "";
                
                var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
                if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                  element.innerHTML = `<h4>Sorry, your transaction could not be processed. Please try again.</h4>`;
                  return actions.restart(); // Recoverable state, per:
                }
                
                await OrderCaptured(orderData.id)
                var transaction =
                  orderData.purchase_units[0].payments.captures[0];

                if (transaction.status == "COMPLETED") {
                  element.innerHTML = `<h4>Donation was successful, you'll be redirected to your sponsored ad. </h4>`;
                  window.location.href = `/generate/${this.user.campaignId}/${this.user.uid}`;
                } else {
                  element.innerHTML = `<h4>Sorry, your transaction could not be processed.</h4>`;
                }
              });
            },
          })
          .render("#payment")
          .catch((er) => {
            console.log("Buttons render failed", er);
            this.$emit("cancel");
          });
      } catch (error) {
        console.log("PayPal SDK Failed ", error);
        this.$emit("cancel");
      }
    },
  },
};
</script>

<style scoped>
</style>