<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <div class="row mb-4">
          <h5 class="text-dark">Dedication</h5>
        </div>
        <div class="row mb-3">
          <div class="col-12 p-0 shadow rounded">
            <vue-editor
              :editor-toolbar="customToolbar"
              v-model="dedicationText"
              placeholder="Text"
              ref="editor"
              @text-change="onTextChange"
            >
            </vue-editor>
          </div>
          <div class="col mt-4">
            <p id="text-count">
              {{
                textcountremain +
                "/" +
                totalAllowedCharacters +
                " characters remaining"
              }}
            </p>
          </div>
        </div>
        <div
          class="relative flex flex-col items-stretch justify-center col-span-2"
        >
          <!-- submit button -->
          <div
            class="col-span-8 flex flex-row items-center justify-center py-1 relative"
          >
            <button
              type="submit"
              @click="goBack"
              id="goBackBtn"
              class="mr-4 sky-blue text-white text-lg block hover:bg-accent rounded-sm w-32 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
            >
              <!-- progress circle -->
              <span
                class="w-full font-display font-bold text-center text-white"
              >
                Go Back
              </span>
            </button>
            <button
              type="submit"
              id="submitBtn"
              @click="dedicationSubmit"
              class="sky-blue text-white text-lg block hover:bg-accent rounded-sm w-32 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
            >
              <!-- progress circle -->
              <template v-if="APILoading">
                <!-- heroicon - progress loader icon -->
                <svg
                  class="relative animate-spin h-5 w-5 mx-auto text-white progress-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </template>
              <template v-else>
                <span
                  class="w-full font-display font-bold text-center text-white"
                >
                  Submit
                </span>
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { OrderCompleted } from "@/services/user.service";

export default {
  name: "Dedication",
  emits: ["payment-submit"],
  data() {
    return {
      customToolbar: [[]],
      dedicationText: undefined,
      loadingSubmit: false,
      showError: false,
      selectedColorIndex: 0,
      totalAllowedCharacters: 180,
      textcountremain: 180,
      colors: {
        primary: "#00aff2",
        secondary: "#87cc74",
        "dark-green": "#41873F",
        "dark-yellow": "#F6BE4F",
        "dark-purple": "#601FB4",
        "sky-blue": "#57BCD5",
      },
    };
  },
  props: {
    gateway: {
      type: Object,
      default: null,
    },
    buttonColor: {
      type: String,
      default: "#00aff2",
    },
    user: {
      type: Object,
      default: null,
    },
    defaultDedicationText: {
      type: String,
      default: undefined,
    },
    APILoading: {
      type: Boolean,
      default: false,
    },
    btnColor: {
      type: String,
    },
  },
  async mounted() {
    document.getElementById("submitBtn").style.background =
      this.colors[this.btnColor];
    this.dedicationText = this.defaultDedicationText;
    document.getElementById("goBackBtn").style.background =
      this.colors[this.btnColor];
    this.dedicationText = this.defaultDedicationText;
  },
  watch: {
    dedicationText: {
      handler: function (val) {
        this.$emit('dedcation',val)
        val = val.replace(/<[^>]+>/g, "");

        if (val[0] == undefined) {
          this.textcountremain = 0;
        }
        if (val) {
          if (val.length <= this.totalAllowedCharacters) {
            document.getElementById("text-count").style.color = "#333333";
            this.textcountremain = this.totalAllowedCharacters - val.length;
            this.textcount = null;
          } else {
            // this.legalTextError.status = false;
            // this.legalTextError.message =
            //   "Title should have less than 80 characters";
            // this.textcount = "Title should have less than 80 characters";
            this.textcountremain = 0;
          }
        }
      },
    },
  },
  methods: {
    onTextChange() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > this.totalAllowedCharacters) {
        document.getElementById("text-count").style.color = "#df4759";
        quill.deleteText(this.totalAllowedCharacters, len);
      }
    },
    primaryColorClick(index) {
      this.selectedColorIndex = index;
    },
    dedicationSubmit() {
      this.loadingSubmit = true;

      const editor = document.getElementsByClassName("ql-editor")[0];
      const rawtext = editor.innerText;

      this.$emit("dedication-submit", {
        dedicationText: rawtext,
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    async initializePayPal(clientId, clientToken, orderId) {
      try {
        this.paypal = await loadScript({
          "client-id": clientId,
          "data-client-token": clientToken,
          components: "hosted-fields",
        });
        if (this.paypal.HostedFields.isEligible()) {
          const cardFields = await this.paypal.HostedFields.render({
            createOrder: function (/*data, actions*/) {
              return orderId;
            },
            styles: {
              ".valid": {
                color: "green",
              },
              ".invalid": {
                color: "red",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          });

          // then(function (cardFields) {
          document
            .querySelector("#card-form")
            .addEventListener("submit", (event) => {
              event.preventDefault();
              this.showError = false;
              this.loadingPay = true;
              cardFields
                .submit({
                  cardholderName:
                    document.getElementById("card-holder-name").value,
                  // Billing Address
                  // billingAddress: {
                  //   countryCodeAlpha2: document.getElementById(
                  //     "card-billing-address-country"
                  //   ).value,
                  // },
                })
                .then(async (data /* actions*/) => {
                  console.log("data then ", data);
                  const capture = await OrderCompleted(orderId);
                  // const orderData = capture.data.content.capture;
                  const user = capture.data.content.user;

                  window.location.href = `/generate/${user.campaignName}/${user.uid}`;
                })
                .catch((error) => {
                  this.loadingPay = false;
                  this.showError = true;
                  // TODO Show Error
                  // const msgs = error?.details?.map((d) => d.description);

                  console.log("Submit failed ", error);
                });
            });
          // });
        } else {
          this.showError = true;
          console.log("NO HOSTED FIELDS");
        }
      } catch (error) {
        this.showError = true;
        console.log("PayPal SDK Failed ", error);
      }
    },
  },
};
</script>

<style scoped>
.color-btn {
  width: 35px;
  height: 35px;
}

.layout-column {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

/* .no-gutters {
    margin-right: -20px;
  } */
.primary {
  background: #b22234;
}

.secondary {
  background: #3c3b6e;
}

.dark-green {
  background: #41873f;
}

.dark-yellow {
  background: #f6be4f;
}

.dark-purple {
  background: #601fb4;
}

.sky-blue {
  background: #00aff2;
}
</style>
