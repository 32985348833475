<template>
  <div class="mt-20">
    <!-- <div class="text-3xl font-bold text-start py-4">Pay with PayPal</div> -->

    <div class="mb-4">
      <VideoCard
        :heightPreview="true"
        :item="selectedVideo"
        :index="99"
        :fnPreview="fnPreview"
        :dedicationText="dedicationText"
        :firstName="firstName"
        :lastName="lastName"
        :city="city"
        :state="state"
        :overlayText="true"
        :slideImage="slideImage"
        :textColor="textColor"
      />
    </div>
    <div class="text-3xl font-bold mb-2 mx-auto text-center">
      {{ user.donationAmount }}
    </div>
    <div class="text-2xl text-prime-gray text-center font-medium mb-1">
      {{ user.campaignName }}
    </div>
  </div>
</template>

<script>
import VideoCard from "@/components/VideoCard.vue";

export default {
  name: "PayPalCard",
  components: {
    VideoCard,
  },
  props: {
    firstName: null,
    lastName: null,
    city: null,
    state: null,
    dedicationText: {
      type: String,
    },
    slideImage:{
      type: String
    },
    textColor:{
      type: String
    },
    font:{
      type: String
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    fnPreview: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectedVideo: null
    };
  },
  created(){
    this.selectedVideo = Object.assign({}, this.selectedItem)
    if(this.slideImage){
      this.selectedVideo.previewImage = this.slideImage;
    }
  },
};
</script>

<style scoped></style>
