<template>
  <!-- single video card -->
  <div
    class="col-span-2 sm:col-span-1 rounded-xl single-video-frame"
    :key="`video-${index}`"
  >
    <!-- image layer (video preview image) -->
    <div
      class="relative w-full z-0 hover:shadow-hard transition-shadow duration-500 ease-in-out overflow-hidden rounded-xl image-container"
    >
      <div style="position: relative">
        <img
          :key="`video-img-${index}`"
          class="relative w-full transition-opacity duration-500 ease-in-out z-1 rounded-xl overflow-hidden object-cover video-preview-image"
          :class="
            heightPreview ? 'ImgPreviewHeightLarge' : 'ImgPreviewHeightSmall'
          "
          :src="item.previewImage"
        />

        <div
          v-if="overlayText"
          style="font-weight: bold"
          :style="{ fontFamily: fontFamily, color: textColor }"
        >
          <p
            v-html="dedicationText"
            style="
              word-wrap: normal;
              overflow-wrap: break-word;
              display: inline-block;
              width: 24ch;
              position: absolute;
              line-height: 0.9;
              top: 35%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 25px;
              text-align: center;
              line-break: normal;
              /* font-weight: bold; */
            "
          ></p>
          <p
            style="
              word-wrap: break-word;
              display: inline-block;
              width: 25ch;
              position: absolute;
              line-height: 1;
              top: 80%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 22px;
              text-align: center;
              line-break: normal;
            "
          >
            {{ firstName }} {{ lastName }}<br />
            {{ city }}, {{ state }}
          </p>
          <!-- <p
          style="
            word-wrap: break-word;
            display: inline-block;
            width: 25ch;
            position: absolute;
            line-height: 1;
            top: 85%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 25px;
            text-align: center;
            line-break: anywhere;
          "
        >
        
        </p> -->
        </div>
      </div>
      <!-- masked overlay with hover actions -->
      <div
        class="absolute flex flex-row items-center justify-center top-0 left-0 w-full h-full z-10 rounded-xl bg-dark masked-overlay"
      >
        <!-- preview video button -->
        <button
          v-if="fnPreview != null"
          class="relative flex flex-col items-center justify-center bg-transparent rounded-sm w-20 h-20 mx-1 text-white hover:text-success transition-colors duration-300 preview-video-button"
          @click="fnPreview"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="w-8 h-8 fill-current"
          >
            <path
              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
            ></path>
            <path d="m9 17 8-5-8-5z"></path>
          </svg>
          <span
            class="text-center text-xs font-display font-normal text-current py-1"
            >Preview</span
          >
        </button>
        <!-- select/selected video button -->
        <button
          v-if="fnSelect != null"
          class="relative flex flex-col items-center justify-center bg-transparent rounded-sm w-20 h-20 mx-1 text-white hover:text-info transition-colors duration-300 preview-video-button"
          @click="fnSelect"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="w-8 h-8 fill-current"
          >
            <!-- toggle the icons based on the isSelected variable -->
            <template v-if="item.isSelected">
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"
              ></path>
            </template>
            <template v-else>
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path
                d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"
              ></path>
            </template>
          </svg>
          <span
            class="text-center text-xs font-display font-normal text-current py-1"
            >{{ item.isSelected ? "Selected" : "Select" }}</span
          >
        </button>
      </div>
    </div>
    <!-- video title -->
    <h6
      :title="item.title"
      class="text-center text-base text-secondary truncate font-display font-light w-full mt-4 mb-1 transition-all duration-300"
      :class="item.isSelected ? 'font-bold' : 'font-light'"
    >
      {{ item.title }}
    </h6>
  </div>
</template>

<script>
export default {
  props: {
    dedicationText: {
      type: String,
    },
    firstName: null,
    lastName: null,
    city: null,
    state: null,
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    fnSelect: {
      type: Function,
      default: null,
    },
    fnPreview: {
      type: Function,
      default: null,
    },
    heightPreview: {
      type: Boolean,
      default: false,
    },
    overlayText: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      height: this.heightPreview,
      fontFamily: "Noto Sans JP",
    };
  },
  async created() {
    if (this.overlayText) {
      const url = `${process.env.VUE_APP_ASSETS_BASE_URL}${this.item.font}`;
      //  "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;600&display=swap"
      const font = new FontFace("Noto Sans JP", `url("${url}")`);

      try {
        const loadedFont = await font.load();
        document.fonts.add(loadedFont);
      } catch (error) {
        console.error(error);
      }
      // this.fontFamily = this.item.font.replace(/\//g,"_");
    }
  },
};
</script>

<style scoped>
/* video modal header opacity transition */
.video-preview-modal > header {
  opacity: 0;
  z-index: 10;
}
.video-preview-modal:hover > header {
  opacity: 1;
}
/* video element max height */
.video-element {
  max-height: 85vh;
}
/* cc card input group */
.cc-input-group .cc-icon {
  padding-left: 3%;
}
.cc-input-group .cc-number-input {
  padding-left: 12%;
  padding-right: 36%;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: padding-left;
}
.cc-input-group .cc-expiry-input-group {
  margin-left: 66%;
}
/* video frame */
.single-video-frame {
  height: max-content;
  min-height: 108px;
}
/* .single-video-frame .image-container .video-preview-image {
  height: 150px;
} */

.ImgPreviewHeightSmall {
  height: 150px;
}
.ImgPreviewHeightLarge {
  height: 300px;
  object-fit: cover;
}
.single-video-frame .image-container .masked-overlay {
  opacity: 0;
  --tw-bg-opacity: 0;
  transition-property: opacity, background-color, border-color, color, fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
/* change the bg opacity and div opacity on hover */
.single-video-frame:hover .image-container .masked-overlay {
  opacity: 1;
  --tw-bg-opacity: 0.6;
}
/* autocomplete CSS */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #8793a3;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
    font-family: "Roboto", sans-serif;
font-size: 0.875rem;
    line-height: 1.25rem;
} */
/* sm and down */
select {
  background-color: #ffffff;
}
</style>
