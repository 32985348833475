<template>
  <!-- card -->
  <div class="main-container">
    <template v-if="!notFound">
      <!-- <div
      class="
        flex flex-col
        items-center
        justify-center
        py-2
        mb-4
        shadow-xl
        bg-light
        banner-container
      "
    > -->
      <!-- banner card -->
      <template v-if="layout">
        <div class="grid grid-cols-1 gap-x-4 gap-y-12 w-full py-3 sm:py-6">
          <div
            class="relative flex flex-col items-center justify-center col-span-2 lg:col-span-1"
          >
            <!-- rectange banner -->
            <div
              class="col-span-2 sm:col-span-1 rounded-xl banner-img-half"
              v-if="layout == 'rectangle'"
            >
              <div
                class="relative w-full z-0 hover:shadow-hard transition-shadow duration-500 ease-in-out overflow-hidden rounded-xl image-container"
              >
                <img
                  :src="bannerImg"
                  alt="Banner Half"
                  class="relative w-full transition-opacity duration-500 ease-in-out z-0 rounded-xl overflow-hidden object-cover rectangle-image"
                />
              </div>
            </div>
            <!-- square banner -->
            <div
              class="col-span-2 sm:col-span-1 rounded-xl banner-img-square"
              v-if="layout == 'square'"
            >
              <div
                class="relative w-full z-0 hover:shadow-hard transition-shadow duration-500 ease-in-out overflow-hidden rounded-xl image-container"
              >
                <img
                  class="relative w-full transition-opacity duration-500 ease-in-out z-0 rounded-xl overflow-hidden object-cover square-image"
                  :src="bannerImg"
                  alt="Banner Squre"
                />
              </div>
            </div>
            <!-- long banner -->
            <div
              class="col-span-2 sm:col-span-1 rounded-xl banner-img-long"
              v-if="layout == 'longBar'"
            >
              <div
                class="relative w-full z-0 hover:shadow-hard transition-shadow duration-500 ease-in-out overflow-hidden rounded-xl image-container"
              >
                <img
                  class="relative w-full transition-opacity duration-500 ease-in-out z-0 rounded-xl overflow-hidden object-cover long-image"
                  :src="bannerImg"
                  alt="Banner Full"
                />
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </template>

      <div
        class="flex flex-col items-center justify-start px-4 sm:px-8 py-2 rounded-xl shadow-xl bg-light card-container formRes"
      >
        <!-- Swordtails==true form displayed -->
        <!-- user info form (with target videos view) -->
        <div
          class="grid grid-cols-2 gap-x-4 gap-y-12 w-full py-3 sm:py-6"
          v-show="activeStep === 'userInfo'"
        >
          <!-- user info form -->
          <div
            class="relative flex flex-col items-stretch justify-start col-span-2 lg:col-span-1"
          >
            <!-- heading -->
            <h1
              class="text-left font-display font-bold text-base sm:text-lg md:text-2xl text-dark mb-2"
            >
              Contribution Information.
            </h1>
            <!-- caption -->
            <!-- <h4 class="text-left font-display font-normal text-xs text-dark">
          Please enter these details about you so we can personalize the PAC ad
          for you.
        </h4> -->
            <form
              action=""
              class="grid grid-cols-8 gap-4 px-3 md:px-0 mt-8 mb-6 sm:mb-0 w-full"
            >
              <!-- single form input (first name) -->
              <div class="col-span-8 sm:col-span-4">
                <label
                  for="first_name"
                  class="block text-sm text-dark font-display font-normal"
                  >First Name (Required)</label
                >
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  v-model="firstName"
                  placeholder="John"
                  required
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                  :class="
                    firstNameError && firstNameError.status == true
                      ? 'border-stroke-light'
                      : firstNameError.status == false
                      ? 'border-danger'
                      : 'border-stroke-light'
                  "
                />
                <!-- error message span class -->
                <span
                  v-if="!firstNameError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ firstNameError.message }}
                </span>
              </div>
              <!-- single form input (last name) -->
              <div class="col-span-8 sm:col-span-4">
                <label
                  for="last_name"
                  class="block text-sm text-dark font-display font-normal"
                  >Last Name (Required)</label
                >
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  v-model="lastName"
                  placeholder="Doe"
                  autocomplete="family-name"
                  required
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                  :class="
                    lastNameError && lastNameError.status == true
                      ? 'border-stroke-light'
                      : lastNameError.status == false
                      ? 'border-danger'
                      : 'border-stroke-light'
                  "
                />
                <!-- error message span class -->
                <span
                  v-if="!lastNameError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ lastNameError.message }}
                </span>
              </div>
              <!-- single form input (email) -->
              <div class="col-span-8 sm:col-span-5">
                <label
                  for="email_address"
                  class="block text-sm text-dark font-display font-normal"
                  >Email Address (Required)</label
                >
                <input
                  type="email"
                  name="email_address"
                  id="email_address"
                  v-model="email"
                  placeholder="johndoe@example.com"
                  autocomplete="email"
                  required
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border shadow-soft"
                  :class="
                    emailError && emailError.status == true
                      ? 'border-stroke-light'
                      : emailError.status == false
                      ? 'border-danger'
                      : 'border-stroke-light'
                  "
                />
                <!-- error message span class -->
                <span
                  v-if="!emailError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ emailError.message }}
                </span>
              </div>
              <!-- single form input (phone) [dummy input/no validation] -->
              <div class="col-span-8 sm:col-span-3">
                <label
                  for="phone"
                  class="block text-sm text-dark font-display font-normal"
                  >Phone</label
                >
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  v-model="phone"
                  placeholder="(555) 555-1234 "
                  autocomplete="tel"
                  @keypress="isNumber($event)"
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border border-stroke-light focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                />
              </div>
              <!-- single form input - full span (address) [dummy input/no validation] -->
              <div class="col-span-8">
                <label
                  for="address"
                  class="block text-sm text-dark font-display font-normal"
                  >Billing Address</label
                >
                <input
                  type="text"
                  name="address"
                  id="address"
                  v-model="address"
                  placeholder="11A, Star Avenue, Palo Alto, California, USA"
                  autocomplete="street-address"
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border border-stroke-light focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                />
              </div>
              <!-- single form input (city) -->
              <div class="col-span-8 sm:col-span-3">
                <label
                  for="city"
                  class="block text-sm text-dark font-display font-normal"
                >
                  City (Required)
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  v-model="city"
                  placeholder="Brooklyn"
                  autocomplete="address-level2"
                  required
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                  :class="
                    cityError && cityError.status == true
                      ? 'border-stroke-light'
                      : cityError.status == false
                      ? 'border-danger'
                      : 'border-stroke-light'
                  "
                />
                <!-- error message span class -->
                <span
                  v-if="!cityError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ cityError.message }}
                </span>
              </div>
              <!-- single form select (state) -->
              <div class="col-span-8 sm:col-span-3">
                <label
                  for="state"
                  class="block text-sm text-dark font-display font-normal"
                >
                  State (Required)
                </label>
                <!-- simple native select -->
                <div class="relative">
                  <select
                    id="state"
                    name="state"
                    v-model="state"
                    autocomplete="address-level3"
                    required
                    class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border focus:ring-secondary focus:border-secondary focus:outline-none transition-colors placeholder-prime-gray shadow-soft appearance-none"
                    :class="[
                      stateError && stateError.status == true
                        ? 'border-stroke-light'
                        : stateError.status == false
                        ? 'border-danger'
                        : 'border-stroke-light',
                      state == null ? 'text-prime-gray' : 'text-dark',
                    ]"
                  >
                    <option
                      v-if="state == null"
                      :value="null"
                      disabled
                      selected
                    >
                      Select your state
                    </option>
                    <!-- list of options -->
                    <template v-for="(item, index) in stateList">
                      <option :value="item.abbreviation" :key="index">
                        {{ item.name }}
                      </option>
                    </template>
                  </select>
                  <span
                    class="pl-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <!-- Heroicon name: selector -->
                    <svg
                      class="h-5 w-5 text-prime-gray"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <!-- error message span class -->
                <span
                  v-if="!stateError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ stateError.message }}
                </span>
              </div>
              <!-- single form input (zip code) [dummy input/no validation] -->
              <div class="col-span-8 sm:col-span-2">
                <label
                  for="zipCode"
                  class="block text-sm text-dark font-display font-normal"
                  >Zip Code</label
                >
                <input
                  type="number"
                  name="zipCode"
                  id="zipCode"
                  v-model="zipCode"
                  placeholder="00001"
                  autocomplete="postal-code"
                  @keypress="isNumber($event)"
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border border-stroke-light focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                />
              </div>
              <!-- single form input (contribution amount) [dummy input/no validation] -->
              <div class="col-span-8 sm:col-span-3">
                <label
                  for="contributionAmount"
                  class="block text-sm text-dark font-display font-normal"
                  >Contribution Amount</label
                >
                <input
                  type="text"
                  name="contributionAmount"
                  id="contributionAmount"
                  v-model="contributionAmount"
                  placeholder="Amount"
                  @input="onKeypressTaxId()"
                  autocomplete="number"
                  @keypress="isNumber($event)"
                  class="block w-full h-10 mt-1 px-3 rounded-sm font-body sm:text-sm border border-stroke-light focus:ring-secondary focus:border-secondary transition-colors placeholder-prime-gray shadow-soft"
                  :class="
                    contributionAmountError &&
                    contributionAmountError.status == true
                      ? 'border-stroke-light'
                      : contributionAmountError.status == false
                      ? 'border-danger'
                      : 'border-stroke-light'
                  "
                />
                <span
                  v-if="!contributionAmountError.status"
                  class="block text-sm text-danger font-body mt-1"
                >
                  {{ contributionAmountError.message }}
                </span>
              </div>

              <!-- single checkbox (user consent) -->
              <div
                class="col-span-8 flex flex-row items-start justify-start w-full mt-1 mx-auto"
              >
                <div class="flex items-center h-5">
                  <input
                    id="consent"
                    name="consent"
                    type="checkbox"
                    v-model="userConsent"
                    required
                    class="focus:ring-secondary h-4 w-4 text-primary border-dark border-opacity-50 rounded"
                  />
                </div>
                <div class="mt-0.5 pl-3 text-sm">
                  <!-- have dummy text if disclaimer text from API is empty -->
                  <template v-if="this.disclaimerText">
                    <label
                      for="consent"
                      class="block text-left text-secondary text-xs md:text-xs font-body font-normal select-none disclaimer-text"
                      v-html="this.disclaimerText"
                    >
                    </label>
                  </template>
                  <template v-else>
                    <!-- <label
                for="consent"
                class="
                  block
                  text-left text-secondary text-xs
                  md:text-xs
                  font-body font-normal
                  select-none
                  disclaimer-text
                "
              >
                I certify that the above information is true and correct and
                that:
                <ol>
                  <li>I am a U.S. Citizen or legal permanent resident,</li>
                  <li>I am not a federal contractor,</li>
                  <li>
                    I am making this contribution from my own funds and that
                    these funds have not been provided to me for the purposes of
                    making a contribution,
                  </li>
                  <li>
                    I agree to accept the
                    <a
                      class="
                        text-primary
                        underline
                        transition-opacity
                        opacity-100
                        hover:opacity-50
                      "
                      target="_blank"
                    >
                      terms &amp; conditions
                    </a>
                    of this platform.
                  </li>
                </ol>
              </label> -->
                  </template>
                </div>
              </div>
              <!-- form error message span class -->
              <span
                v-if="!formError.status"
                class="col-span-8 text-center text-sm text-danger font-body mt-1"
              >
                {{ formError.message }}
              </span>
              <!-- submit button -->
              <div
                class="relative hidden lg:flex flex-row items-center justify-center col-span-8 py-1"
              >
                <button
                  id="submitBtnId"
                  class="block hover:bg-accent rounded-sm w-32 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
                  @click="submitUserInfo"
                >
                  <!-- progress circle -->
                  <template v-if="isAPILoading">
                    <!-- heroicon - progress loader icon -->
                    <svg
                      class="relative animate-spin h-5 w-5 mx-auto text-white progress-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </template>
                  <template v-else>
                    <span
                      class="w-full font-display font-bold text-center text-white"
                    >
                      Donate!
                    </span>
                  </template>
                </button>
              </div>
            </form>
          </div>
          <!-- target videos view -->
          <div
            class="relative flex flex-col items-stretch justify-start col-span-2 lg:col-span-1"
          >
            <!-- heading -->
            <h1
              class="text-left font-display font-bold text-base sm:text-lg md:text-2xl text-dark mb-2"
            >
              Please select the ad you’d like to sponsor.
            </h1>
            <!-- caption -->
            <!-- <h4 class="text-left font-display font-normal text-xs text-dark">
          Please select one of the advertisements below to personalize it.
        </h4> -->
            <!-- target videos list -->
            <div
              class="flex flex-col items-center justify-center flex-grow px-3 md:px-0 mt-8 mb-6 sm:mb-0 w-full"
            >
              <transition name="fade-slow" mode="out-in">
                <template v-if="targetVideosLoading">
                  <!-- heroicon - progress loader icon -->
                  <svg
                    class="relative animate-spin h-12 w-12 mx-auto text-dark progress-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </template>
                <template v-else>
                  <!-- target videos list -->
                  <div
                    class="grid grid-cols-2 grid-rows-3 gap-4 flex-grow w-full"
                  >
                    <VideoCard
                      v-for="(item, index) in targetVideosList"
                      :key="index"
                      :item="item"
                      :index="index"
                      :fnSelect="
                        () => {
                          toggleSelectVideo(item);
                        }
                      "
                      :fnPreview="
                        () => {
                          openPreviewVideoModal(item);
                        }
                      "
                    />
                    <!-- single column -->
                  </div>
                </template>
              </transition>
            </div>
            <div
              class="flex flex-col items-center justify-center flex-grow w-full p-2"
            >
              <img class="logo-img" :src="logoImg" />
            </div>
          </div>
          <!-- submit button container (only show on smaller screens where the forms are stacked on top of each other) -->
          <div
            class="relative flex lg:hidden flex-col items-stretch justify-center col-span-2"
          >
            <!-- submit button -->
            <div
              class="col-span-8 flex flex-row items-center justify-center py-1 relative"
            >
              <button
                id="submitBtnIdMobile"
                class="block hover:bg-accent rounded-sm w-32 py-2 transition-all duration-200 shadow-hard hover:shadow-2xl"
                @click="submitUserInfo"
              >
                <!-- progress circle -->
                <template v-if="isAPILoading">
                  <!-- heroicon - progress loader icon -->
                  <svg
                    class="relative animate-spin h-5 w-5 mx-auto text-white progress-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </template>
                <template v-else>
                  <span
                    class="w-full font-display font-bold text-center text-white"
                  >
                    Donate!
                  </span>
                </template>
              </button>
            </div>
          </div>
          <div
            v-for="(error, index) in errors"
            id="errormsg"
            class="col-span-8 bg-rose-600 rounded text-white p-2 m-6"
            :key="index"
          >
            {{ typeof error == "string" ? error : error.html }}
          </div>
        </div>
        <template v-if="activeStep === 'dedication'">
          <div class="container">
            <h1 class="h4 text-capitalize mt-5 mb-5 font-weight-bold">
              share your dedication
            </h1>
            <div class="row align-items-start">
              <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
                <DedicationInfo
                  :selectedItem="selectedItem"
                  :dedicationText="dedicationText"
                  :firstName="firstName"
                  :lastName="lastName"
                  :city="city"
                  :state="state"
                  :user="paypalUser"
                  :slideImage="slideImage"
                  :textColor="textColor"
                  :fnPreview="
                    () => {
                      openPreviewVideoModal(selectedItem);
                    }
                  "
                />
              </div>

              <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
                <Dedication
                  @dedication-submit="onDedicationSubmit"
                  :default-dedication-text="defaultDedicationText"
                  @goBack="goBack"
                  :APILoading="isAPILoading"
                  :btnColor="btnColor"
                  @dedcation="add"
                />
              </div>
            </div>
          </div>
          <div
            v-for="(error, index) in errors"
            id="errormsg"
            class="col-span-8 bg-rose-600 rounded text-white p-2 m-6"
            :key="index"
          >
            {{ typeof error == "string" ? error : error.html }}
          </div>
        </template>
        <template v-if="activeStep === 'payment'">
          <div class="container">
            <h1 class="h4 text-capitalize mt-5 font-weight-bold">
              Pay with PayPal
            </h1>
            <div class="row align-items-start">
              <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
                <CheckoutInfo
                  :selectedItem="selectedItem"
                  :dedicationText="dedicationText"
                  :firstName="firstName"
                  :lastName="lastName"
                  :city="city"
                  :state="state"
                  :slideImage="slideImage"
                  :textColor="textColor"
                  :user="paypalUser"
                  :fnPreview="
                    () => {
                      openPreviewVideoModal(selectedItem);
                    }
                  "
                />
              </div>
              <div class="col col-lg-6 col-md-12 col-sm-12 col-12">
                <div id="paypal-container">
                  <PayPalCard
                    v-if="gatewayUI === 'PAYPAL_ADV'"
                    :gateway="paypalInfo"
                    :user="paypalUser"
                    :buttonColor="colors[buttonColor]"
                    @cancel="onPaymentCancel"
                  />
                  <PayPalStandard
                    v-if="gatewayUI === 'PAYPAL_STD'"
                    :gateway="paypalInfo"
                    :user="paypalUser"
                    :buttonColor="colors[buttonColor]"
                    @cancel="onPaymentCancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- video preview modal (with fade transition) -->
        <transition name="fade" mode="out-in">
          <template v-if="showVideoPreviewModal">
            <div class="fixed z-40 inset-0 overflow-y-auto">
              <div
                class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block lg:p-0"
              >
                <!-- background overlay -->
                <div
                  class="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div class="absolute inset-0 bg-black opacity-60"></div>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                  >&#8203;</span
                >
                <!-- modal content -->
                <div
                  class="inline-block align-center bg-white rounded-xl overflow-hidden shadow-hard transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full video-preview-modal"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <!-- modal header -->
                  <header
                    class="absolute top-0 w-full flex flex-row items-center justify-between p-2 pl-4 transition-opacity ease-in-out duration-500"
                  >
                    <h2
                      class="text-left text-base sm:text-lg font-display font-bold text-white truncate"
                    >
                      Preview Video - {{ this.currentPreviewVideoTitle }}
                    </h2>
                    <button
                      type="button"
                      class="rounded-full p-1 text-white hover:text-prime-gray transition-colors duration-300 focus:outline-none"
                      @click="closePreviewVideoModal"
                    >
                      <span class="sr-only">Close Modal</span>
                      <!-- Heroicon name: outline/x -->
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </header>
                  <!-- modal inner content -->
                  <div
                    class="flex flex-col items-start justify-start bg-dark modal-inner-content"
                  >
                    <video
                      width="100%"
                      height="auto"
                      autoplay="autoplay"
                      controls="controls"
                      class="video-element"
                      :src="currentPreviewVideoURL"
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition>
      </div>
      <b-overlay :show="layoutDataLoading" no-wrap></b-overlay>
    </template>
    <!-- not found page -->
    <template v-else>
      <b-container class="align position">
        <b-row class="justify-content-center" no-gutters>
          <img
            alt="Give Proudly"
            src="@/assets/logo/giveProudly-log.svg"
            class="img-sized"
          />
        </b-row>
        <b-row class="justify-content-center">
          <span class="errorCode">404</span>
        </b-row>
        <b-row class="justify-content-center">
          <span class="error">Page not found</span>
        </b-row>
        <b-row class="justify-content-center pt-3">
          <span class="errorDis"
            >Whoops...We can't seem to find the page you are looking for.</span
          >
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import States from "@/util/States";

// util
import CheckEmail from "@/util/CheckEmail";

// mixins
import isNumber from "@/Mixin/isNumber";

//store
import { mapGetters } from "vuex";

//services
import { CheckoutSession } from "@/services/user.service";
// import { GetAllTargetVideos } from "@/services/targetVideos.service";
import { GetDisclaimerText } from "@/services/content.service";
import { GetData } from "@/services/campaign.service";

import PayPalCard from "@/components/payment/PayPalCard";
import PayPalStandard from "@/components/payment/PayPalStandard";
import VideoCard from "@/components/VideoCard.vue";
import CheckoutInfo from "@/components/payment/CheckoutInfo.vue";
import donation from "@/assets/img/donation.jpg";
import Dedication from "../components/Dedication.vue";
import DedicationInfo from "../components/payment/DedicationInfo.vue";

export default {
  name: "UserInfo",
  components: {
    PayPalCard,
    VideoCard,
    CheckoutInfo,
    PayPalStandard,
    Dedication,
    DedicationInfo,
  },
  mixins: [isNumber],
  data() {
    return {
      dedicationText: null,
      btnColor: null,
      activeStep: "userInfo",
      steps: ["userInfo", "payment"],
      currentStepIndex: 0,
      isShowEnableDisableModal: false,
      rowItem: null,
      showEnableDisableModalKey: Number(new Date()) + 100,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      employer: null,
      occupation: null,
      employmentStatus: "yes",
      address: null,
      city: null,
      state: null,
      stateList: [],
      zipCode: null,
      cardId: null,
      expiryDate: null,
      cvcNumber: null,
      contributionAmount: null,
      paymentInfo: null,
      userConsent: false,
      isAPILoading: false,
      targetVideosList: [],
      targetVideosLoading: true,
      layoutDataLoading: false,
      showVideoPreviewModal: false,
      currentPreviewVideoTitle: null,
      currentPreviewVideoURL: null,
      disclaimerText: null,
      logoImg: null,
      bannerImg: null,
      backgroundImage: null,
      formError: {
        status: null,
        message: "",
      },
      firstNameError: {
        status: null,
        message: "",
      },
      lastNameError: {
        status: null,
        message: "",
      },
      cityError: {
        status: null,
        message: "",
      },
      stateError: {
        status: null,
        message: "",
      },
      emailError: {
        status: null,
        message: "",
      },
      layout: "",
      buttonColor: "",
      buttonTextColor: "",
      contributionAmountError: {
        status: null,
        message: "",
      },
      minimumDonation: null,
      notFound: false,
      showpaypalcard: false,
      showDetails: true,
      paypalInfo: null,
      paypalUser: null,
      gatewayUI: null,
      selectedItem: null,
      defaultDedicationText: undefined,
      colors: {
        primary: "#00aff2",
        secondary: "#87cc74",
        "dark-green": "#41873F",
        "dark-yellow": "#F6BE4F",
        "dark-purple": "#601FB4",
        "sky-blue": "#57BCD5",
      },
      slideImage: null,
      textColor: null,
      // Payload sent to server
      payload: {},
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "users/getUserDetails",
    }),
    // check basic form validations
    computedInputValidations() {
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        CheckEmail(this.email) &&
        this.city &&
        this.state &&
        this.userConsent
      ) {
        return true;
      }
      return false;
    },
    // check if a video is selected from the video list
    computedSelectedVideoValidation() {
      // method tests whether at least one element in the target videos array has one item with isSelected as true
      return this.targetVideosList.some((element) => element.isSelected);
    },
    // return the video
    getSelectedVideo() {
      // method return the value of the first element in the target videos array which has isSelected as true
      return this.targetVideosList.find((element) => element.isSelected);
    },
  },
  watch: {
    firstName(val) {
      if (val) {
        this.firstNameError.status = true;
      } else {
        this.firstNameError.status = false;
        this.firstNameError.message = "Please fill in your first name.";
      }
    },
    lastName(val) {
      if (val) {
        this.lastNameError.status = true;
      } else {
        this.lastNameError.status = false;
        this.lastNameError.message = "Please fill in your last name.";
      }
    },
    city(val) {
      if (val) {
        this.cityError.status = true;
      } else {
        this.cityError.status = false;
        this.cityError.message = "Please fill in your city.";
      }
    },
    state(val) {
      if (val) {
        this.stateError.status = true;
      } else {
        this.stateError.status = false;
        this.stateError.message = "Please select your state.";
      }
    },
    email(val) {
      if (val) {
        if (CheckEmail(val) == true) {
          this.emailError.status = true;
        } else {
          this.emailError.status = false;
          this.emailError.message = "Please fill in a valid e-mail address.";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your e-mail address.";
      }
    },
    contributionAmount(val) {
      if (val) {
        var newVal = val.slice(1);

        if (newVal < this.minimumDonation) {
          this.contributionAmountError.status = false;
          this.contributionAmountError.message = `Minimum donation amount is $${this.minimumDonation}.`;
        } else {
          this.contributionAmountError.status = true;
        }
      } else {
        this.contributionAmountError.status = false;
        this.contributionAmountError.message =
          "Please fill in your contibution amount";
      }
    },
  },
  mounted() {
    // get the disclaimer text from API
    this.getDisclaimerText();
    // load the target videos API to poplate taret videos list
    this.loadCampaign();
    // populate the states array
    this.stateList = States;
    // load layout data
    // this.populateLayoutDetails();
  },
  methods: {
    add(val) {
      this.dedicationText = val;
    },
    goBack() {
      this.errors = [];
      this.currentStepIndex--;
      if (this.currentStepIndex < 0) this.currentStepIndex = 0;

      this.activeStep = this.steps[this.currentStepIndex];
    },
    onPaymentCancel() {
      this.goBack();
      setTimeout(() => {
        document.getElementById("submitBtnId").style.backgroundColor =
          this.colors[this.buttonColor];
        document.getElementById("submitBtnIdMobile").style.backgroundColor =
          this.colors[this.buttonColor];
      }, 500);
    },
    // populate disclaimer text
    async getDisclaimerText() {
      try {
        let { data } = await GetDisclaimerText();
        if (data.code == 200 || data.message == "success") {
          this.disclaimerText = data.content.disclaimer;
        }
      } catch {
        console.log("ERROR UI01");
      }
    },

    async loadCampaign() {
      try {
        this.targetVideosLoading = true;
        this.layoutDataLoading = true;

        const campaignNameId = this.$route.params.nameId;

        let res = await GetData(campaignNameId).catch(() => {
          /// only error occured
          document.getElementById("backgroundImgId").style.background =
            "url(" +
            "https://storage.googleapis.com/assets.giveproudly.com/bg_login.jpg";
          (")");
          document.getElementById("backgroundImgId").style.backgroundSize =
            "cover";
          return;
        });
        const data = res.data;

        if (data.code == 200 || data.message == "success") {
          this.btnColor = data.content.layout.primaryColor;
          document.getElementById("submitBtnId").style.backgroundColor =
            this.colors[data.content.layout.primaryColor];
          document.getElementById("submitBtnIdMobile").style.backgroundColor =
            this.colors[data.content.layout.primaryColor];

          this.targetVideosList = data.content.videos.map((x) => {
            // then map them
            return {
              _id: x._id,
              title: x.videoTitle,
              previewImage: x.videoPreviewImage,
              videoURL: x.video,
              font: x.font,
              // assign a new parameter to toggle selected video status
              isSelected: false,
            };
          });

          // SET CAMPAIGN LAYOUT
          const layout = data.content.layout || {};

          this.gatewayUI = data.content.layout.gateway ?? "PAYPAL_STD";
          console.log("GATEWAY UX ", this.gatewayUI);

          this.layout = layout.bannerType ? layout.bannerType : null;
          this.buttonColor = layout.primaryColor
            ? layout.primaryColor
            : "primary";
          this.buttonTextColor = layout.secondaryColor
            ? layout.secondaryColor
            : null;
          this.logoImg = layout.logo ? layout.logo[0] : null;
          this.bannerImg = layout.bannerImage ? layout.bannerImage[0] : null;
          this.backgroundImage = layout.backgroundImage
            ? layout.backgroundImage[0]
            : null;

          this.slideImage = layout.slideImage;
          this.textColor = layout.textColor;
          // document.getElementById("submitBtnId").style.backgroundColor =
          //   "#00aff2";
          // document.getElementById("submitBtnIdMobile").style.backgroundColor =
          //   "#00aff2";

          if (this.backgroundImage != null) {
            document.getElementById("backgroundImgId").style.background =
              "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))," +
              "url(" +
              this.backgroundImage +
              ")";
            document.getElementById("backgroundImgId").style.backgroundSize =
              "cover";
          } else {
            document.getElementById("backgroundImgId").style.background =
              "linear-gradient(0deg, rgba(135, 204, 116, 0.4), rgba(135, 204, 116, 0.4))," +
              `url(${donation})`;
            document.getElementById("backgroundImgId").style.backgroundSize =
              "cover";
          }
          this.minimumDonation = layout.minimumDonation
            ? layout.minimumDonation
            : 5;

          // Set flow
          if (layout.donorCustomizableMessage) {
            this.steps = ["userInfo", "dedication", "payment"];
            console.log("defaultDedicationText", layout.defaultDonorCustomText);
            this.defaultDedicationText = layout.defaultDonorCustomText;
          }
        } else {
          this.notFound = true;
        }
      } catch (e) {
        this.notFound = true;
        console.log("ERROR UI02");
      } finally {
        this.targetVideosLoading = false;
        this.layoutDataLoading = false;
      }
    },

    async nextStep(fn) {
      if (fn) {
        const success = await fn();
        if (!success) return;
      }
      this.currentStepIndex++;

      if (this.steps[this.currentStepIndex] == "payment") {
        const { success, errors } = await this.createCheckoutSession();
        if (!success) {
          this.currentStepIndex--;
          if (errors && errors.length > 0) {
            console.log("Next step errors,", errors);
            this.errors = errors;
          } else {
            this.errors.push({
              type: "danger",
              html: "Something went wrong. Please try again.",
            });
          }
          return;
        }
      }

      if (this.currentStepIndex + 1 > this.steps.length) {
        console.log("ALL STEPS ARE COMPLETED");
        return;
      } else {
        this.activeStep = this.steps[this.currentStepIndex];
      }
    },

    submitUserInfo(e) {
      this.nextStep(() => this.submitUserForm(e));
    },

    onDedicationSubmit(e) {
      console.log(e);
      this.payload.dedication = e;
      this.nextStep();
    },

    submitPaymentInfo() {
      console.log("button click");
      this.nextStep(this.paymentSubmit);
    },
    async paymentSubmit() {
      console.log("Payment accept");
    },
    // function to add the user info and send to the database
    async submitUserForm(e) {
      // prevent the form from being triggered natively
      e.preventDefault();
      // reset the form error message
      this.formError.message = null;

      // validating the form and target videos
      if (
        this.computedInputValidations &&
        this.computedSelectedVideoValidation &&
        this.getSelectedVideo
      ) {
        this.payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          city: this.city,
          state: this.state,
          email: this.email,
          contributionAmount: parseInt(this.contributionAmount.slice(1).trim()),
          targetVideo: this.getSelectedVideo
            ? this.getSelectedVideo.videoURL
            : null,
          targetVideoId: this.getSelectedVideo
            ? this.getSelectedVideo._id
            : null,
          targetVideoTitle: this.getSelectedVideo
            ? this.getSelectedVideo.title
            : null,
          targetVideoPreviewImage: this.getSelectedVideo
            ? this.getSelectedVideo.previewImage
            : null,
          campaignNameId: this.$route.params.nameId,

          zipCode: this.zipCode,
          phone: this.phone,
          isCurrentlyEmployed: this.employmentStatus,
          employer: this.employer,
          occupation: this.occupation,
          address: this.address,
        };
        // console.log(this.payload);
        return true;
      } else {
        this.errorFn();
        return false;
      }
    },

    async createCheckoutSession() {
      console.log(this.payload);
      try {
        this.isAPILoading = true;
        let { data } = await CheckoutSession(this.payload);
        if (data.code == 200 || data.message == "success") {
          // Redirect to checkout page
          // window.location.replace(data.content.redirect);
          this.paypalInfo = data.content.gateway;
          this.paypalUser = data.content.user;

          return { success: true };

          // this.showDetails = false;
          // this.showpaypalcard = true;
          // this.paypalUser =
          // let userDetails = data.content;
          // this.$store.dispatch("users/setUserDetails", userDetails);
          // localStorage.setItem(
          //   "personalpac-user",
          //   JSON.stringify(userDetails)
          // );

          // // then navigate to next screen
          // this.$router.replace(`/generate/${userDetails._id}`);
        } else {
          this.formError.status = false;
          this.formError.message = `Server error: ${data.message}`;
          return {
            success: false,
            errors: data?.details,
          };
        }
      } catch (error) {
        console.log("ERROR UI03", error);
        if (error.response.data && error.response.data.details) {
          return {
            success: false,
            errors: error.response.data.details,
          };
        }
        return {
          success: false,
          errors: [
            {
              type: "danger",
              html: "Something went wrong. Please try again.",
            },
          ],
        };
      } finally {
        this.isAPILoading = false;
      }
    },
    // preview video modal functions
    openPreviewVideoModal(item) {
      // make the modal visible
      this.showVideoPreviewModal = true;
      // populate the video details into data parameters to be passed to the modal
      this.currentPreviewVideoTitle = item.title;
      this.currentPreviewVideoURL = item.videoURL;
      document.body.querySelector("#paypal-container").style.display = "none";
    },
    closePreviewVideoModal() {
      // hide the modal
      this.showVideoPreviewModal = false;
      // reset the video details
      this.currentPreviewVideoURL = null;
      this.currentPreviewVideoTitle = null;
      document.body.querySelector("#paypal-container").style.display = "block";
    },
    // toggle video selection
    toggleSelectVideo(item) {
      this.selectedItem = item;
      // check if the item is selected or not
      if (item.isSelected) {
        // if the item is selected, toggle it
        item.isSelected = !item.isSelected;
      } else {
        // if the item is not selected, then unselect all the others and the set this item as selected
        // first unselect all the video items
        this.targetVideosList.forEach((element) => {
          element.isSelected = false;
        });

        // then set isSelected true for the currently selected item
        // get the unique id of the current item
        let _id = item._id;
        this.targetVideosList.forEach((element, index) => {
          if (element._id == _id) {
            this.$set(this.targetVideosList[index], "isSelected", true);
          } else {
            this.$set(this.targetVideosList[index], "isSelected", false);
          }
        });
      }
    },
    truncateText(val, maxLength) {
      if (val.length > maxLength) {
        return val.substring(0, maxLength) + "...";
      }
      return val;
    },
    // mask text for card number accordingly
    isCardNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;

      // check if a max character limit is reached then go to next input field
      let target = evt.srcElement || evt.target;
      let myLength = target.value.length;
      if (myLength >= 19) {
        let nextParent = target.nextElementSibling;
        let innerChildren = nextParent.children;
        for (let i = 0; i < innerChildren.length; i++) {
          let innerChild = innerChildren[i];
          if (innerChild == null) break;
          if (innerChild.tagName.toLowerCase() === "input") {
            innerChild.focus();
            break;
          }
        }
      }

      // check if the entered character is a numeric character (ignore any other types of characters)
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        this.ccFormat();
        return true;
      }
    },
    ccFormat() {
      let value = this.cardId;
      value = value ? value.replace(/[^\d/]/g, "") : "";

      let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      // let matches = v.match(/\d{4,16}/g);
      let regex = /\d{4,16}/g;
      let matches = regex.exec(v);
      let match = (matches && matches[0]) || "";
      let parts = [];

      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }

      if (parts.length) {
        this.cardId = parts.join(" ");
      } else {
        this.cardId = value;
      }
    },
    // mask text for card expiry date
    isCardExpiryDate(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;

      // check if a max character limit is reached then go to next input field
      let target = evt.srcElement || evt.target;
      let myLength = target.value.length;
      if (myLength >= 5) {
        let next = target;
        while ((next = next.nextElementSibling)) {
          if (next == null) break;
          if (next.tagName.toLowerCase() === "input") {
            next.focus();
            break;
          }
        }
      } // Move to previous field if empty (user pressed backspace)
      else if (myLength === 0) {
        var parent = target.parentElement;
        var previous = parent;
        while ((previous = previous.previousElementSibling)) {
          if (previous == null) break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
          }
        }
      }

      // check if the entered character is a numeric character (ignore any other types of characters)
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        this.ccDateFormat();
        return true;
      }
    },
    ccDateFormat() {
      let value = this.expiryDate;
      value = value ? value.replace(/[^\d/]/g, "") : "";

      let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      let regex = /\d{2,4}/g;
      let matches = regex.exec(v);
      let match = (matches && matches[0]) || "";
      let parts = [];

      for (let i = 0, len = match.length; i < len; i += 2) {
        parts.push(match.substring(i, i + 2));
      }

      if (parts.length) {
        this.expiryDate = parts.join("/");
      } else {
        this.expiryDate = value;
      }
    },
    // mask text for card cvc
    isCardCVC(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;

      // check if a max character limit is reached then go to next input field
      let target = evt.srcElement || evt.target;
      let myLength = target.value.length;
      if (myLength >= 3) {
        evt.preventDefault();
      } // Move to previous field if empty (user pressed backspace)
      else if (myLength === 0) {
        var previous = target;
        while ((previous = previous.previousElementSibling)) {
          if (previous == null) break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
          }
        }
      }

      // check if the entered character is a numeric character (ignore any other types of characters)
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    errorFn() {
      if (!this.firstName) {
        this.firstNameError.status = false;
        this.firstNameError.message = "Please fill in your first name.";
      }
      if (!this.lastName) {
        this.lastNameError.status = false;
        this.lastNameError.message = "Please fill in your last name.";
      }
      if (!this.city) {
        this.cityError.status = false;
        this.cityError.message = "Please fill in city.";
      }
      if (!this.state) {
        this.stateError.status = false;
        this.stateError.message = "Please select your state.";
      }
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your e-mail addresss.";
      }
      if (!CheckEmail(this.email)) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in a valid e-mail address.";
      }
      if (!this.computedSelectedVideoValidation) {
        this.formError.status = false;
        this.formError.message =
          "Please select atleast one video from the PAC advertisement list. ";
      }
      if (!this.userConsent) {
        this.formError.status = false;
        this.formError.message = this.formError.message
          ? (this.formError.message +=
              "And please check the user consent checkbox in order to continue.")
          : (this.formError.message =
              "Please check the user consent checkbox in order to continue.");
      }
      if (!this.minimumDonation) {
        this.contributionAmountError.status = false;
        this.contributionAmountError.message = "Please fill in your last name.";
      } else if (this.contributionAmount < this.minimumDonation) {
        this.contributionAmountError.status = false;
        this.contributionAmountError.message = `Minimum donation amount is $${this.minimumDonation}.`;
      }
    },
    onKeypressTaxId() {
      var str = this.contributionAmount;
      str = str.replace("$ ", "");
      if (str.length > 0) {
        str = str.substring(0, 0) + "$ " + str.substring(0);
      }
      this.contributionAmount = str;
    },
    openEnabDisabModal(item, button) {
      this.isShowEnableDisableModal = true;
      this.rowItem = item;
      this.$root.$emit(
        "bv::show::modal",
        "enable-disable-coupon-row-modal",
        button
      );
    },
    onCloseEnableDisableModal() {
      this.isShowEnableDisableModal = false;
    },
  },
};
</script>

<style scoped>
/*Imaged Radio Buttons*/
.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

/*Fit image in bootstrap div*/
.fit-image {
  width: 100%;
  object-fit: cover;
}

/* end style */

/* video modal header opacity transition */
.video-preview-modal > header {
  opacity: 0;
  z-index: 10;
}

.video-preview-modal:hover > header {
  opacity: 1;
}

/* video element max height */
.video-element {
  max-height: 85vh;
}

/* cc card input group */
.cc-input-group .cc-icon {
  padding-left: 3%;
}

.cc-input-group .cc-number-input {
  padding-left: 12%;
  padding-right: 36%;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: padding-left;
}

.cc-input-group .cc-expiry-input-group {
  margin-left: 66%;
}

/* video frame */
.single-video-frame {
  height: max-content;
  min-height: 108px;
}

.single-video-frame .image-container .video-preview-image {
  height: 150px;
}

.single-video-frame .image-container .masked-overlay {
  opacity: 0;
  --tw-bg-opacity: 0;
  transition-property: opacity, background-color, border-color, color, fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* change the bg opacity and div opacity on hover */
.single-video-frame:hover .image-container .masked-overlay {
  opacity: 1;
  --tw-bg-opacity: 0.6;
}

/* disclaimer text styling */
/* .disclaimer-text {} */
/* ordered list inside the disclaimer */
.disclaimer-text ol,
.disclaimer-text > ol,
.disclaimer-text >>> ol {
  position: relative;
  margin-top: 0.5rem;
  padding-left: 1.25rem;
  list-style-type: decimal;
  list-style-position: outside;
}

/* unordered list inside the disclaimer */
.disclaimer-text ul,
.disclaimer-text > ul,
.disclaimer-text >>> ul {
  position: relative;
  margin-top: 0.5rem;
  padding-left: 1.25rem;
  list-style-type: circle;
  list-style-position: outside;
}

/* anchor link inside the disclaimer */
.disclaimer-text a,
.disclaimer-text > a,
.disclaimer-text >>> a {
  text-decoration: underline;
  color: rgba(178, 34, 52);
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.logo-img {
  max-width: 200px;
}

/* banner styling */
.banner-container {
  margin: auto;
}

.banner-img-half,
.banner-img-long {
  height: max-content;
  min-height: 180px;
}

.banner-img-half .image-container .rectangle-image {
  height: 270px;
  width: 360px;
}

.banner-img-long .image-container .long-image {
  height: 180px;
  width: 1080px;
}

.banner-img-square {
  height: max-content;
  min-height: 150px;
}

.banner-img-square .image-container .square-image {
  height: 150px;
  width: 150px;
}

/* change the bg opacity and div opacity on hover */
.single-video-frame:hover .image-container .masked-overlay {
  opacity: 1;
  --tw-bg-opacity: 0.6;
}

.img-sized {
  height: 150px;
  width: 150px;
}

.errorCode {
  width: 193px;
  height: 110px;

  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 112px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.error {
  width: 374px;
  height: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.errorDis {
  width: 367px;
  height: 68px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #9fa2b4;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.logoImg {
  width: 150px;
  height: 53.96px;
}

.align {
  margin-left: 12%;
  padding-top: 3%;
}

@media (min-width: 992px) {
  .position {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, 50%);
  }
}

.testing {
  color: #b22234;
  background-color: #b22234;
  background: #b22234;
}

/* autocomplete CSS */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #8793a3;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
    font-family: "Roboto", sans-serif;
font-size: 0.875rem;
    line-height: 1.25rem;
} */
/* sm and down */
select {
  background-color: #ffffff;
}

@media (max-width: 36rem) {
  /* cc card input group */
  .cc-input-group .cc-icon {
    opacity: 0;
  }

  .cc-input-group .cc-number-input {
    padding-left: 3%;
    padding-right: 45%;
  }

  .cc-input-group .cc-expiry-input-group {
    margin-left: 60%;
  }

  .banner-img-half .image-container .rectangle-image {
    height: 180px;
    width: 800px;
  }
}

@media (max-width: 78rem) {
  .banner-img-half .image-container .rectangle-image {
    height: auto;
    width: auto;
    max-width: 65vw;
  }

  .banner-img-long .image-container .long-image {
    height: auto;
    width: auto;
    padding-bottom: 5px;
    max-width: 85vw;
  }
}

@media (max-width: 991.98px) {
  .banner-img-half .image-container .rectangle-image {
    height: 270px;
    width: 360px;
    margin-bottom: 5%;
  }

  .formRes {
    margin-top: -10px;
  }

  .banner-img-long .image-container .long-image {
    height: auto;
    width: auto;
    padding-bottom: 5px;
    max-width: 90vw;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .align {
    margin-left: 0%;
    padding-top: 10%;
  }

  .errorCode {
    font-size: 35px;
    margin-top: -35px;
  }

  .errorDis {
    font-size: 12px;
  }

  .error {
    font-size: 20px;
    margin-top: -30px;
  }

  .logoImg {
    width: 90px;
    height: 53.96px;
  }

  .banner-img-half .image-container .rectangle-image {
    height: auto;
    width: auto;
    margin-bottom: 40%;
  }

  .formRes {
    margin-top: -90px;
  }

  .banner-img-long .image-container .long-image {
    height: auto;
    width: auto;
    margin-bottom: 80px;
  }

  .banner-img-square .image-container .square-image {
    height: 150px;
    width: 150px;
    margin-bottom: 100px;
  }
}
</style>
